<template>
  <default-table-body-row>
    <v-col cols="12" sm="6" md="2">
      <span class="d-block d-md-none font-weight-bold">Pedido</span>
      <span
        class="d-block text-wrap font-weight-bold secondary--text mb-n2"
        :title="pedido.numeroPedido"
      >
        {{ pedido.numeroPedido }}
      </span>
      <span
        class="text-overline font-weight-light text--text"
        :title="pedido.dataProducao | dateDMY"
      >
        {{ pedido.dataProducao | dateDMY }}
      </span>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <span class="d-block d-md-none font-weight-bold">Cliente</span>
      <span class="d-block text-wrap mb-n1" :title="pedido.nomeCliente">
        {{ pedido.nomeCliente }}
      </span>
      <span
        class="text-wrap font-weight-light secondary--text text-caption"
        :title="pedido.kitDescricao"
      >
        {{ pedido.kitDescricao }}
      </span>
    </v-col>
    <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="">
      <span class="d-block d-md-none font-weight-bold">Transportadora</span>
      <span class="d-block text-wrap mb-n1" :title="pedido.transportadora">
        {{ pedido.transportadora }}
      </span>
      <span
        class="text-wrap font-weight-light secondary--text text-caption"
        :title="pedido.dataFaturamento"
      >
        {{ pedido.dataFaturamento }}
      </span>
    </v-col>
    <v-col sm="6" md="3" lg="3" xl="3" class="">
      <span
        v-if="tempoDePatio"
        class="font-italic text-danger font-weight-bold"
        style="font-size: 13.3px"
        >No pátio há {{ tempoDePatio }}</span
      >
    </v-col>
    <v-col cols="3" sm="2" md="1">
      <default-fab
        tooltip-text="Ver Detalhes"
        @click="openModalMaisDetalhes(pedido)"
        ><v-icon>mdi-archive</v-icon></default-fab
      >
    </v-col>
  </default-table-body-row>
</template>

<script>
import DefaultFab from "../../../shared/components/vuetify/DefaultFab.vue";
import DefaultTableBodyRow from "../../../shared/components/vuetify/DefaultTableBodyRow.vue";
import PedidoWebRepository from "@/repositories/v2/PedidoWebRepository";
import ListagemPedidosService from "../../../services/listagem_pedidos/ListagemPedidosService";

export default {
  components: {
    DefaultTableBodyRow,
    DefaultFab,
  },
  created() {
    this.ListagemPedidosService = ListagemPedidosService.build().setVm(this);
    this.pedidoWebRepository = PedidoWebRepository.build();
  },

  props: {
    pedido: {
      type: Object,
      required: true,
    },
  },
  methods: {
    timeSince(dateString) {
      const date = new Date(dateString);
      const differenceMs = Date.now() - date.getTime();
      const differenceSeconds = Math.floor(differenceMs / 1000);
      const differenceMinutes = Math.floor(differenceSeconds / 60);
      const differenceHours = Math.floor(differenceMinutes / 60);
      const differenceDays = Math.floor(differenceHours / 24);
      let result = "";
      if (differenceDays > 0) {
        result += `${differenceDays} dia${differenceDays !== 1 ? "s" : ""}`;
        if (differenceHours % 24 > 0 || differenceMinutes % 60 > 0) {
          result += ", ";
        }
      }

      if (differenceHours % 24 > 0) {
        result += `${differenceHours % 24} hora${
          differenceHours % 24 !== 1 ? "s" : ""
        }`;
        if (differenceMinutes % 60 > 0) {
          result += ", ";
        }
      }

      if (differenceMinutes % 60 > 0) {
        result += `${differenceMinutes % 60} minuto${
          differenceMinutes % 60 !== 1 ? "s" : ""
        }`;
      }

      if (result === "") {
        return result;
      }

      return this.formatTempoDePatio(result);
    },
    formatTempoDePatio(value) {
      return value
        .replace(/^([^,]+),([^,]+)$/, "$1 e $2")
        .replace(/^([^,]+),([^,]+),([^,]+)$/, "$1, $2 e $3");
    },

    openModalMaisDetalhes(pedido) {
      this.$emit("openModalMaisDetalhes", pedido);
    },
  },
  computed: {
    tempoDePatio() {
      return this.timeSince(this.pedido.dataProducao);
    },
  },
};
</script>

<style scoped>
.default-button-style {
  text-align: center;
  outline: none;
  border-radius: 1rem;
  padding: 0.7rem;
  font-weight: bold !important;
  font-family: inherit;
  font-size: 14px;
  cursor: pointer;
  transition: background-color, padding 0.15s;
  background-color: #cc5d02 !important;
  color: white !important;
  border: none;
}

.default-button-style:hover {
  background-color: #e6781e !important;
  color: white !important;
}

.-last {
  list-style-type: none;
  padding: 1.25rem;
  text-align: center;
}
.listing-card {
  list-style-type: none;
  width: 100%;
  height: 100%;
  background-color: #eef0f8;
  color: #2e2e2e;
  border-radius: 1rem;
}
.listing-card:hover {
  background-color: rgba(215, 219, 223, 0.84);
  border-radius: 1rem;
  transition: 300ms all ease-in-out;
}
</style>
