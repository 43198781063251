<template>
  <b-modal data-bv
    body-class="rounded"
    hide-footer
    size="lg sm"
    hide-header
    centered
    id="modal-mais-detalhes"
  >
    <div class="mt-2">
      <div class="d-block text-center">
        <h4 class="font-weight-bold">
          DETALHES DO PEDIDO #{{ pedido.numeroPedido }}
        </h4>
      </div>

      <b-row class="mt-4 text-center">
        <b-col>
          <b-col>
            <h6 class="font-weight-bold">Cliente</h6>
            <p>{{ pedido.nomeCliente }}</p>
          </b-col>
          <b-col>
            <h6 class="font-weight-bold">Kit</h6>
            <p>{{ pedido.kitDescricao }}</p>
          </b-col>
          <b-col>
            <h6 class="font-weight-bold">Tipo de Pedido</h6>
            <p>{{ tipoDePedido }}</p>
          </b-col>
        </b-col>

        <b-col>
          <b-col>
            <h6 class="font-weight-bold">Status de Pagamento</h6>
            <p style="color: #e74432">
              {{ statusPagamento }}
            </p>
          </b-col>
          <b-col>
            <h6 class="font-weight-bold">Status do Pedido</h6>
            <p>{{ pedido.statusPedido }}</p>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    pedido: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statusPagamento() {
      const pagamentoStatus = this.pedido.pagamentoStatus
        ? "PAGO"
        : "NÃO LIBERADO";
      return pagamentoStatus;
    },
    tipoDePedido() {
      const tipoDePedido = this.pedido.tipoPedido.toUpperCase();
      return tipoDePedido;
    },
  },
};
</script>
