<template>
  <v-container fluid>
    <listing-header
      @filter="filter"
      title="Pedidos Não Expedir"
      :isButtonLoading="ListagemPedidosService.loading"
    />
    <default-table-head class="d-none d-md-flex">
      <v-col cols="2" class="border-r">
        <span class="d-block text-wrap font-weight-bold mb-n2">Pedido</span>
        <span class="text-caption"> Data Produção </span>
      </v-col>
      <v-col cols="3" class="border-r">
        <span class="d-block text-wrap font-weight-bold mb-n2">Cliente</span>
        <span class="text-caption">Descrição do Kit</span>
      </v-col>
      <v-col cols="3" class="border-r">
        <span class="d-block text-wrap font-weight-bold mb-n2"
          >Transportadora</span
        >
        <span class="text-caption"> Dt Faturamento </span>
      </v-col>
      <v-col cols="3" class="border-r">
        <span class="d-block text-wrap font-weight-bold mb-n2"
          >Data Produção</span
        >
      </v-col>
      <v-col cols="1" class=""> </v-col>
    </default-table-head>

    <template v-if="!ListagemPedidosService.loading">
      <div v-if="!pedidos.length">
        <v-card class="py-7 background" elevation="0">
          <v-icon large color="red" class="mr-4">
            mdi-alert-circle-outline
          </v-icon>
          <span class="font-weight-bold text-uppercase">
            Nenhum resultado encontrado para hoje.
          </span>
        </v-card>
      </div>
      <div v-else>
        <listing-card
          @openModalMaisDetalhes="openModalMaisDetalhes"
          :pedido="pedido"
          v-for="(pedido, index) in pedidos"
          :key="index"
        />
      </div>
    </template>
    <div v-else class="pad mt-5" style="text-align: center !important">
      <default-spinner />
    </div>
    <div class="">
      <default-pagination
        v-model="pagination.currentPage"
        :length="pagination.lastPage"
        @input="paginate(pagination.currentPage)"
        :disabled="pedidos.length === 0"
      />
    </div>
    <modal-mais-detalhes :pedido="pedido" />
  </v-container>
</template>

<script>
import DefaultSpinner from "../../../shared/components/vuetify/DefaultSpinner.vue";

import ListagemPedidosService from "../../../services/listagem_pedidos/ListagemPedidosService";
import DefaultPagination from "@/shared/components/vuetify/DefaultPagination.vue";
import DefaultTableHead from "../../../shared/components/vuetify/DefaultTableHead.vue";
import ListingHeader from "./../../souserials/listagem_pedidos/ListingHeader.vue";
import ListingCard from "../components/ListingCard.vue";
import ModalMaisDetalhes from "../components/ModalMaisDetalhes.vue";

export default {
  name: "NaoExpedirView",
  components: {
    ListingHeader,
    ListingCard,
    DefaultPagination,
    ModalMaisDetalhes,
    DefaultTableHead,
    DefaultSpinner,
  },
  props: {
    // },
  },
  data: () => ({
    pedido: {
      numeroPedido: "",
      nomeCliente: "",
      kitDescricao: "",
      dataProducao: "",
      statusPedido: "",
      tipoPedido: "",
      pagamentoStatus: "",
      transportadora: "",
      dataFaturamento: "",
    },
    page: 1,
    pedidos: [],
    pagination: {
      total: 0,
      perPage: 0,
      currentPage: 1,
      lastPage: 0,
      previousPage: 0,
      nextPage: 0,
    },
    filtro: { dataInicial: "", dataFinal: "" },
    ListagemPedidosService: ListagemPedidosService.build(),
  }),
  beforeMount() {
    this.getDate();
  },
  created() {
    this.ListagemPedidosService = ListagemPedidosService.build().setVm(this);
  },
  async mounted() {
    await this.ListagemPedidosService.setStatus(this.pedidosStatus).list(
      this.page,
      this.filtro
    );
  },
  watch: {
    async pedidosStatus() {
      await this.ListagemPedidosService.setStatus(this.pedidosStatus).list(
        this.page,
        this.filtro
      );
    },
  },
  methods: {
    openModalMaisDetalhes(pedido) {
      this.pedido = pedido;
      this.$bvModal.show("modal-mais-detalhes");
    },
    getDate() {
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1;
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();

      this.filtro.dataInicial = year + "-" + month + "-" + day;
      this.filtro.dataFinal = year + "-" + month + "-" + day;
    },
    async paginate(page) {
      await this.ListagemPedidosService.list(page, this.filtro);
    },
    async filter(params) {
      this.filtro = params;
      await this.ListagemPedidosService.list(
        this.pagination.currentPage,
        this.filtro
      );
    },
  },
  computed: {
    pedidosStatus() {
      return this.$attrs.status;
    },
    listColor() {
      return "navy-color";
    },
  },
};
</script>

<style scoped>
.list-titles {
  list-style-type: none;
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
  padding: 1.25rem;
}
li {
  font-weight: normal;
  font-size: 1rem;
}
.list-component {
  background-color: #eef0f8;
  color: #ffffff;
  border-radius: 1rem;
}
.listing-component-navy-color {
  background: #120947;
}
.listing-component-blue-color {
  background: #3699ff;
}
.listing-component-green-color {
  background: #2eb03b;
}
.listing-component-red-color {
  background: #721212;
}
.listing-component-yellow-color {
  background: #dd9402;
}
.listing-component-orange-color {
  background: #c25904;
}
.listing-component-gray-color {
  background: #3f3f3f;
}
</style>
